<template>
  <main class="ml-12">
    <trac-loading v-if="isLoading" />
    <trac-validation-observer slim v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(sendProductAdjustment)">
        <div class="wrapper">
          <div class="flex flex-row justify-between items-end">
            <ul class="flex-col mt-12 mb-8">
              <li>Inventory Control</li>
              <li class="font-bold mt-4 text-2xl">Add Stock</li>
            </ul>
            <div class="mb-8">
              <trac-button buttonType="submit" class="uppercase"
                >Confirm</trac-button
              >
            </div>
          </div>
        </div>
        <div class="px-8 py-8 bg-white rounded-lg big-shadow">
          <span class="uppercase text-sm font-semibold">Item</span>
          <div class="mt-8">
            <div
              v-for="(item, i) in productsToAdjust"
              :key="i"
              class="items-center"
            >
              <div class="grid grid-cols-3 gap-5 mt-2">
                <div>
                  <span class="text-sm font-bold capitalize">{{
                    item.title
                  }}</span>
                </div>
                <div class="flex-column">
                  <trac-validation-provider
                    name="stock unit"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <trac-input
                      v-model.number="item.stock"
                      placeholder="Enter No. of Units"
                    ></trac-input>
                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>
                </div>
                <div class="flex justify-center">
                  <trac-validation-provider
                    name="reason"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <trac-dropdown
                      v-model="item.update_reason"
                      :menuItems="removeOptions"
                      @input="selectReason"
                      title="Reason for update"
                    ></trac-dropdown>

                    <trac-input-error v-if="errors[0]">{{
                      errors[0]
                    }}</trac-input-error>
                  </trac-validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <trac-modal v-if="newReasonModal" @close="newReasonModal = false">
          <EnterReason></EnterReason>
        </trac-modal>
      </form>
    </trac-validation-observer>
  </main>
</template>

<script>
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
} from "../../../browser-db-config/localStorage";
import EnterReason from "./EnterReason";
import { eventBus } from "./../../../main";

export default {
  name: "AddStockNext",
  components: { EnterReason },
  data() {
    return {
      isLoading: false,
      removeOptions: [
        { name: "Theft" },
        { name: "Damaged" },
        { name: "Unexplained" },
      ],
      selectedStore: null,
      productsToAdjust: null,
      newReasonModal: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!GET_LOCAL_DB_DATA("stock-adjustment-store-detail")) {
      next({ name: "RemoveStock" });
    } else {
      next();
    }
  },
  created() {
    this.selectedStore = GET_LOCAL_DB_DATA("stock-adjustment-store-detail");

    this.productsToAdjust = GET_LOCAL_DB_DATA("products-to-adjust").map(
      (product) => {
        return {
          store_stock: product.store_stock,
          title: product.title,
          _id: product._id,
          business_id: GET_USER_BUSINESS_ID(),
          stock: "",
          store_id: this.selectedStore._id,
          update_reason: "",
          variant_id: product.is_local_variant ? product._id : "",
          variation: product.is_local_variant || false,
        };
      }
    );
  },
  methods: {
    selectReason(reason) {
      // (reason);
    },
    selectedStoreStock(product) {
      return product.store_stock.find(
        (ss) => ss.store_id === this.selectedStore._id
      );
    },
    optionSelected() {
      this.newReasonModal = true;
    },
    async sendProductAdjustment() {
      const payload = this.productsToAdjust.map((product) => {
        delete product.title;
        product.stock =
          (this.selectedStoreStock(product).stock || 0) -
          (product.stock === "" ? 0 : product.stock);
        return product;
      });

      this.isLoading = true;

      // Send data
      await this.$store.dispatch("BULK_UPDATES", payload);
      const res = this.$store.getters["GET_BULK_UPDATE_RES"];

      this.$store.commit("SET_UP_BULK_UPDATE_RES", null);

      if (res.status) {
        this.$store.dispatch("REFRESH_ALL_PRODUCTS", payload);
        this.$router.replace({ name: "StockAdjustment" });
      } else {
        // alert(res.message || res.error);
        eventBus.$emit("trac-alert", { message: res.message || res.error });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
