import { render, staticRenderFns } from "./RemoveStockNext.vue?vue&type=template&id=35844f91&scoped=true&"
import script from "./RemoveStockNext.vue?vue&type=script&lang=js&"
export * from "./RemoveStockNext.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35844f91",
  null
  
)

export default component.exports