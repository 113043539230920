<template>
  <main class="mt-16 mb-8 mx-5">
    <div class="mb-8 ">
      <h1 class="uppercase font-medium">
        Enter Reason for <span class="font-bold">Blue Pearl Jam</span>
      </h1>
      <svg
        width="20"
        height="4"
        viewBox="0 0 20 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 2C0 0.895431 0.895431 0 2 0H18C19.1046 0 20 0.895431 20 2C20 3.10457 19.1046 4 18 4H2C0.895431 4 0 3.10457 0 2Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="-9.46895"
            y1="2.21242"
            x2="-7.84897"
            y2="9.88217"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#06B6CB" />
            <stop offset="1" stop-color="#253B95" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <trac-textarea placeholder="Enter specific reason here"></trac-textarea>
    <div class="flex flex-row justify-end mt-5">
      <trac-button class="uppercase">Add</trac-button>
    </div>
  </main>
</template>

<script>
export default {
  name: "EnterReason",
};
</script>

<style scoped></style>
